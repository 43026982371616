import React, { useState } from 'react';
import './statusdemo.css';

const StatusDemo = (props) => {
  const [status, setStatus] = useState(Math.floor(Math.random() * 4));

  const lesStatus = ['Normal', 'Advisory', 'Watch', 'warning'];
  const lesICClasses = ['ic-green', 'ic-yellow', 'ic-orange', 'ic-red'];
  const lesLightsClasses = ['light-green', 'light-yellow', 'light-orange', 'light-red'];

  const handleClick = () => {
      let new_status = status + 1;
      new_status = new_status > 3 ? 0 : new_status;
      setStatus(new_status);
  }

  return (
    <div className="status-demo">
      <div className="external-circle" onClick={handleClick}>
        <div className="middle-circle">
          <div className={`internal-circle ${lesICClasses[status]}`}>
            <div className={`light ${lesLightsClasses[status]}`}>
            </div>
          </div>
        </div>
      </div>
      <div className="status-text">{lesStatus[status]}</div>
    </div>
  )
}

export default StatusDemo;
