import React, { useState } from 'react';
import '../css/reference.css';
import Paragraphe from './Paragraphe';
import Modale from './Modale';

const Picture = (props) =>
{
  const [modaleOpen, setModaleOpen] = useState(false);

  const toggleModale = () => {
    setModaleOpen(!modaleOpen);
  }

  return (
    <div className="picture" onClick={toggleModale}>
      {modaleOpen && <Modale onClick={toggleModale}><img className="imgfull" src={props.picture} alt={props.picture} /></Modale>}
      <img src={props.picture} alt={props.picture} />
    </div>
  )
}
const Reference = (props) =>
{


  return (
  <div className="reference">
    <div className="entete">
      <div><span className="customer">{props.reference.content.customer}</span></div>
      <div className="synopsis">{props.reference.content.synopsis}</div>
    </div>
    <div className="presentations">
      {props.reference.content.presentations.map((presentation, index) => { return <Paragraphe key={index} content={presentation} /> })}
    </div>
    <div className="pictures">
      {props.reference.content.pictures.map((picture, index) =>
        {
          return <Picture key={index} picture={picture} />
        })
      }
    </div>
  </div>
  );
}

export default Reference;
