import React, { useState } from 'react';
import '../css/reactpage.css';
import Card from '../components/Card';
import HamburgerDemo from '../components-demo/hamburger/HamburgerDemo';
import ToggleButtonDemo from '../components-demo/togglebutton/ToggleButtonDemo';
import FlipCard from '../components-demo/flip-card/FlipCard';
import StatusDemo from '../components-demo/status/StatusDemo';

const ReactPage = (props) => {
  const [hamburgerVisible, setHamburgerVisible] = useState(false);
  const [toggleButtonOption, setToggleButtonOption] = useState('off');
  return (
    <div className="reactpage">
      <div className="image"></div>
      <div className="components-list">
        <Card
          {...props}
          cardParameters={{title: "hamburger_title", explanations: "hamburger_explanations"}}
          className="carte"
        >
          <HamburgerDemo onClick={() => setHamburgerVisible(!hamburgerVisible)} visible={hamburgerVisible} />
          {`${props.getTerm(props.language, "valeur")} : ${hamburgerVisible ? props.getTerm(props.language, "ouvert") : props.getTerm(props.language, "fermé")}`}
        </Card>
        <Card
          {...props}
          cardParameters={{title: "circle_loader_title", explanations: "circle_loader_explanations"}}
          className="carte"
        >
          <svg className="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
            <circle cx="170" cy="170" r="160" stroke="var(--tonic-color)"/>
            <circle cx="170" cy="170" r="135" stroke="var(--primary-color)"/>
            <circle cx="170" cy="170" r="110" stroke="var(--tonic-color)"/>
            <circle cx="170" cy="170" r="85" stroke="var(--secondary-color)"/>
          </svg>
        </Card>
        <Card
          {...props}
          cardParameters={{title: "toggle_button_title", explanations: "toggle_button_explanations"}}
          className="carte"
        >
          <ToggleButtonDemo width={75} option1="on" option2="off" selected={toggleButtonOption} onChangeOption={(option) => setToggleButtonOption(option)} />
          <div style={{ marginBottom: '10px' }}></div>
          <ToggleButtonDemo width={120} option1="on" option2="off" selected={toggleButtonOption} onChangeOption={(option) => setToggleButtonOption(option)} />
          <div style={{ marginBottom: '10px' }}></div>
          <ToggleButtonDemo width={100} habillage="slide" option1="on" option2="off" selected={toggleButtonOption} onChangeOption={(option) => setToggleButtonOption(option)} />
          <div style={{ marginBottom: '10px' }}></div>
          {`${props.getTerm(props.language, "valeur")} : ${toggleButtonOption}`}
        </Card>
        <Card
          {...props}
          cardParameters={{title: "flip_card_title", explanations: "flip_card_explanations"}}
          className="carte"
        >
          <FlipCard {...props} />
        </Card>
        <Card
          {...props}
          cardParameters={{title: "status_title", explanations: "status_explanations"}}
          className="carte"
        >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', width: '100%'}}>
            <StatusDemo {...props} />
            <StatusDemo {...props} />
            <StatusDemo {...props} />
            <StatusDemo {...props} />
          </div>
        </Card>
        <Card
          {...props}
          cardParameters={{title: "pulse_title", explanations: "pulse_explanations"}}
          className="carte"
        >
          <div className="pulse-container">
            <h1>Attraction</h1>
            <svg className="pulse" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <circle cx="512" cy="512" r="512"></circle>
              <circle cx="512" cy="512" r="512"></circle>
          		<circle cx="512" cy="512" r="512"></circle>
            </svg>
          </div>
		    </Card>
      </div>
    </div>
  );
}

export default ReactPage;
