import React from 'react';
import Reference from '../components/Reference';
import '../css/references.css';

const References = (props) => {
    if (props.references.hasOwnProperty('err')) { return <div>{props.references.err}</div>}
    return (
      <div className="references">
        <div className="image"></div>
        <div className="references-list">
          {props.references.map((reference, index) => { return <Reference key={index} reference={reference} />})}
        </div>
      </div>
    );
}

export default References;
