import contents from './contents.json';

class Contents {
  constructor() {
    this.contents = contents;
  }

  getLanguageContents = (_language) => {
      const languageContents = this.contents.find(language => language.id === _language);
      if (languageContents === undefined ) return {err: "language not implemented"};
      return languageContents;
  }

  getContent = (_language, _contentId) => {
    const language = this.getLanguageContents(_language);
    const content = language.contents.find(content => content.id === _contentId);
    if (content === undefined ) return {err: "content not implemented"};
    return content;
  }

  getReferences = (_language) => {
    const language = this.getLanguageContents(_language);
    const references = language.contents.filter(content => content.type === "reference");
    if (references.length === 0 ) return {err: "no references"};
    return references;
  }

  getContacts = (_language) => {
    const language = this.getLanguageContents(_language);
    const contacts = language.contents.filter(content => content.type === "contact");
    if (contacts.length === 0 ) return {err: "no contacts"};
    return contacts;
  }
}

export default new Contents();
