import React from 'react';
import CardContact from '../components/CardContact';
import '../css/contact.css';

const Contact = (props) => {
    return (
      <div className="contact">
        <div className="image"></div>
        <div className="contacts-list">
          {props.contacts.map((contact, index) => { return <CardContact key={index} contact={contact} />})}
          <div className="toomuch"><p>{props.getTerm(props.language, "too_much")}</p></div>
        </div>

      </div>
    );
}

export default Contact;
