import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import './App.css';
import Datastore from './datastore/datastore';
import TopBar from './components/TopBar';
import Home from './pages/Home';
import References from './pages/References';
import ReactPage from './pages/ReactPage';
import Contact from './pages/Contact';

class App extends Component {
  constructor(props) {
    super(props);
    this.state={
      language: 'fr',
      rgpd: true
    }
  }

  handleMenu = (page) => {
      this.setState({ pageSelected: page });
  }

  handleLanguage = (language) => {
    this.setState({ language });
  }

  handleRGPD = () => {
    this.setState({ rgpd: false });
  }

  render() {
    const WrappedTopBar = withRouter(TopBar);
    return (
      <div className="App">
        <WrappedTopBar
          language={this.state.language}
          getTerm={Datastore.languages.getTerm}
          navlinks={Datastore.routes}
          onChangeLanguage={this.handleLanguage}
          selected='home'
        />
        <div className="container">
          <Route
            exact path="/"
            render={(props) => <Home language={this.state.language} getParagraphe={Datastore.contents.getContent} rgpd={this.state.rgpd} handleRGPD={this.handleRGPD} />}
          />
          <Route
            exact path="/references"
            render={(props) => <References references={Datastore.contents.getReferences(this.state.language)} />}
          />
          <Route
            exact path="/react-components"
            render={(props) => <ReactPage language={this.state.language} getTerm={Datastore.languages.getTerm} />}
          />
          <Route
            exact path="/contact"
            render={(props) => <Contact contacts={Datastore.contents.getContacts(this.state.language)} language={this.state.language} getTerm={Datastore.languages.getTerm} />}
          />
        </div>
      </div>
    );
  }
}

export default App;
