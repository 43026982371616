import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Hamburger from './Hamburger';
import ToggleButton from './ToggleButton';
import '../css/topbar.css';

class TopBar extends Component {
  constructor(props) {
    super(props);
    const current_page = this.props.navlinks.find(navlink => navlink.link === this.props.location.pathname);
    this.state = {
      selected: current_page.title,
      hamburgerVisible: false,
    }
  }

  handleClick = (navlinkTitle) => {
    this.setState({ selected: navlinkTitle, hamburgerVisible: !this.state.hamburgerVisible });
  }

  handleClickHamburger = () => {
    this.setState({ hamburgerVisible: !this.state.hamburgerVisible })
  }

  handleLanguageChange = (option1Selected) => {
    const language = option1Selected ? 'fr' : 'en';
    this.props.onChangeLanguage(language);
  }

  renderNavlink(navlink) {
    const classes = this.state.selected === navlink.title ? "navlink selected" : "navlink";
    return (
      <Link
        key={navlink.link}
        className={classes}
        to={navlink.link}
        onClick={() => this.handleClick(navlink.title)}>
        {this.props.getTerm(this.props.language, navlink.title)}
      </Link>
    );
  }

  render() {
    return (
      <div className="menu-bar">
        <div className="barre-logo">
          <div className="logo">grimoire systems</div>
          <div className="toggle-button">
            <ToggleButton option1="fr" option2="en" selected={this.props.language} onChangeOption={this.handleLanguageChange}/>
          </div>
          <Hamburger onClick={() => this.handleClickHamburger()} visible={this.state.hamburgerVisible}/>
        </div>
        <nav className={!this.state.hamburgerVisible ? "" : "nav-open"}>
          {this.props.navlinks.map(navlink => this.renderNavlink(navlink))}
        </nav>
      </div>
    );
  }
}

export default TopBar;
