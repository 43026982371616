import Languages from './languages/languages.js';
import Contents from './contents/contents.js';
import Routes from './routes/routes.json';

class Datastore {
  constructor() {
    this.languages = Languages;
    this.contents = Contents;
    this.routes = Routes;
  }
}

export default new Datastore();
