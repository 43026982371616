import React, { useState } from 'react';
import './flipcard.css';

const FlipCard = (props) => {

  const [touchScreen, setTouchScreen] = useState(false);
  const [front, setFront] = useState(true);
  const [rotate, setRotate] = useState(false);

  window.addEventListener('touchstart', function onFirstTouch() {
    setTouchScreen(true);
    window.removeEventListener('touchstart', onFirstTouch, false);
  }, false);

  const backFlip = () => {
    setFront(!front);
    setRotate(true);
  }

  const renderNoTouchScreen = () => (
    <div className="flip-card">
        <div className="flip-card-front">
          <h2>{props.getTerm(props.language, "face_avant")}</h2>
          <p className="flip-card-p">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Sed porta sapien eu pulvinar dignissim. Cras posuere suscipit erat,
            vel imperdiet elit efficitur et. Donec sit amet dolor arcu.
          </p>
        </div>
        <div className="flip-card-back">
          <h2>{props.getTerm(props.language, "face_arrière")}</h2>
          <p className="flip-card-p">
            Maecenas dui velit, tincidunt non accumsan nec, feugiat vel purus.
            Aenean et massa ut turpis lacinia condimentum. Cras bibendum, tellus
            ut hendrerit posuere, orci ex ullamcorper ipsum, tempor commodo quam
            tellus sed orci.
          </p>
        </div>
    </div>
  )

  const renderTouchScreen = () => (
    <div className={!rotate ? "touchscreen-flip-card" : "touchscreen-flip-card rotate"} onClick={backFlip} onAnimationEnd={() => setRotate(false)}>
      { front
        ?
          <div className="touchscreen-flip-card-front">
            <h2>{props.getTerm(props.language, "face_avant")}</h2>
            <p className="flip-card-p">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Sed porta sapien eu pulvinar dignissim. Cras posuere suscipit erat,
              vel imperdiet elit efficitur et. Donec sit amet dolor arcu.
            </p>
          </div>
        :
          <div className="touchscreen-flip-card-back">
            <h2>{props.getTerm(props.language, "face_arrière")}</h2>
            <p className="flip-card-p">
              Maecenas dui velit, tincidunt non accumsan nec, feugiat vel purus.
              Aenean et massa ut turpis lacinia condimentum. Cras bibendum, tellus
              ut hendrerit posuere, orci ex ullamcorper ipsum, tempor commodo quam
              tellus sed orci.
            </p>
          </div>
        }
    </div>
  )

  return (
    <div className="flip-card-container">
      {touchScreen ? renderTouchScreen() : renderNoTouchScreen()}
    </div>
  )
}

export default FlipCard;
