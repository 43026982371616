import React from 'react';
import '../css/cardcontact.css';

const CardContact = (props) => {
  return (
    <div className="card-contact">
      <div className="icon"><img src={props.contact.content.icon} /></div>
      <div className="info">
        <div className="name">{props.contact.content.name}</div>
        <div className="reach">{ props.contact.content.link !== 'none' ? <a href={props.contact.content.link}>{props.contact.content.reach}</a> : props.contact.content.reach}</div>
      </div>
    </div>
  )
}

export default CardContact;
