import React from 'react';
import './hamburger-demo.css';

const HamburgerDemo = (props) =>
{
  const classes = props.visible ? "hamburger-demo-close" : "hamburger-demo";
  return (
  <div className={classes} onClick={props.onClick}>
    <div className="line line1"></div>
    <div className="line line2"></div>
    <div className="line line3"></div>
  </div>
  );
}

export default HamburgerDemo;
