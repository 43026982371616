import React from 'react';
import '../css/card.css';
import Paragraphe from './Paragraphe';

const Card = (props) => {
  return (
    <div className="card">
      <div className="title">{props.getTerm(props.language, props.cardParameters.title)}</div>
      <div className="content">{props.children}</div>
      <div className="explanations"><Paragraphe content={{ type: "text", content: props.getTerm(props.language, props.cardParameters.explanations) }} /></div>
    </div>
  )
}

export default Card;
