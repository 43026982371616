import React, { Component } from 'react';
import Paragraphe from '../components/Paragraphe';
import '../css/home.css';

class Home extends Component {
  render() {
    return (
      <div className="home">
        <div className="image"></div>
        { this.props.rgpd
          &&
        <div className="rgpd" onClick={this.props.handleRGPD}>
          <Paragraphe content={this.props.getParagraphe(this.props.language, 6)} />
        </div> }
        <div className="presentation">
          <Paragraphe content={this.props.getParagraphe(this.props.language, 1)} />
          <Paragraphe content={this.props.getParagraphe(this.props.language, 2)} />
          <Paragraphe content={this.props.getParagraphe(this.props.language, 3)} />
        </div>
      </div>
    );
  }
}

export default Home;
