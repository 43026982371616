import React, { Component } from 'react';
import '../css/paragraphe.css';

class Paragraphe extends Component {

  renderRow = (ligne, index) => {
    return (
      <li className="paragrapheArrayRow" key={index}>
        <img className="listPicture" src={ligne.picture} alt={ligne.picture}/>
        <span className="listTexte" dangerouslySetInnerHTML={{__html: ligne.content}} />
      </li>
    )
  };

  convert = (content) => {
    let parag = {};
    if (content.type === 'list') {
      parag = (
        <ul className="paragrapheArray">
          {content.content.map((ligne, index) => { return this.renderRow(ligne, index) })}
        </ul>
      )
    } else {
      parag = <p className="paragraphe"><span dangerouslySetInnerHTML={{__html: content.content}} /></p>;
    }
    return parag;
  }

  render() {
    return (
      <div className="bloc">
        {this.convert(this.props.content)}
      </div>
    )
  }
}

export default Paragraphe;
