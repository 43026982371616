import languages from './languages.json';

class Languages {
  constructor() {
    this.languages = languages;
  }

  getTerm = (_language, _term) => {
    const language = this.languages.find(language => language.id === _language);
    if (language === undefined ) return "language not implemented";
    if (language.hasOwnProperty(_term)) return language[_term];
    return "term not implemented";
  }
}

export default new Languages();
