import React from 'react';
import '../css/modale.css';

const Modale = (props) => {
  return (
    <div className="filtre" onClick={() => props.onClick}>
      <div className="modale">
        <div className="content">{props.children}</div>
      </div>
    </div>
  )
}

export default Modale;
