import React from 'react';
import '../css/hamburger.css';

const Hamburger = (props) =>
{
  const classes = props.visible ? "hamburger close" : "hamburger";
  return (
  <div className={classes} onClick={props.onClick}>
    <div className="line line1"></div>
    <div className="line line2"></div>
    <div className="line line3"></div>
  </div>
  );
}

export default Hamburger;
