import React from 'react';
import './togglebutton-demo.css';

const ToggleButtonDemo = (props) => {

  const styles = {
      interrupteur: {
        bouton_externe: "demo bouton_externe",
        bouton_interne_option1_choisie: "demo bouton_interne_option1_choisie",
        bouton_interne_option2_choisie: "demo bouton_interne_option2_choisie",
        option1_normale: "demo option1",
        option2_normale: "demo option2",
        option1_choisie: "demo option1 toggle-selected",
        option2_choisie: "demo option2 toggle-selected"
      },
      slide: {
        bouton_externe: "demo slide bouton_externe",
        bouton_interne_option1_choisie: "demo slide bouton_interne_option1_choisie",
        bouton_interne_option2_choisie: "demo slide bouton_interne_option2_choisie",
        option1_normale: "demo slide option1",
        option2_normale: "demo slide option2",
        option1_choisie: "demo slide option1 toggle-selected",
        option2_choisie: "demo slide option2 toggle-selected"
      }
  }

  const style = props.habillage === "slide" ? styles.slide : styles.interrupteur;

  const option1Choisie = props.selected === props.option1;

  const toggleButton = () => {
    const retour = option1Choisie ? props.option2 : props.option1;
    props.onChangeOption(retour);
  }

  return (
    <div style={{ width: `${props.width}px` }}>
      <div className={style.bouton_externe} style={{ borderRadius: `${props.width/4}px` }}>
        <div className={option1Choisie ? style.bouton_interne_option1_choisie : style.bouton_interne_option2_choisie} onClick={toggleButton}  style={{ borderRadius: `${props.width/4}px` }}>
          <div className={option1Choisie ? style.option1_choisie : style.option1_normale} style={{ fontSize: `${props.width*0.2}px` }}>{props.option1}</div>
          <div className={!option1Choisie ? style.option2_choisie : style.option2_normale} style={{ fontSize: `${props.width*0.2}px`}}>{props.option2}</div>
        </div>
      </div>
    </div>
  )
}

export default ToggleButtonDemo;
