import React, { useState, useEffect, useRef } from 'react';
import '../css/togglebutton.css';

function useEffectSkipFirst(fn, arr) {
  const isFirst = useRef(true);

  useEffect(() => {
    if (isFirst.current) {
      isFirst.current = false;
      return;
    }

    fn();
  }, arr);
}

const ToggleButton = (props) => {
  const [option1Choisie, setOption1Choisie] = useState(props.option1 === props.selected);

  useEffectSkipFirst(
      () => {
        props.onChangeOption(option1Choisie);
      },
      [option1Choisie]
    );

  const toggleButton = () => {
    setOption1Choisie(!option1Choisie);
  }
  return (
    <div className="bouton_externe">
      <div className={option1Choisie ? "bouton_interne_option1_choisie" : "bouton_interne_option2_choisie"} onClick={toggleButton}>
        <div className={option1Choisie ? "option1 toggle-selected" : "option1"}>{props.option1}</div>
        <div className={!option1Choisie ? "option2 toggle-selected" : "option2"}>{props.option2}</div>
      </div>
    </div>
  )
}

export default ToggleButton;
